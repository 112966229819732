import { graphql, useStaticQuery } from "gatsby";
import NotebookCard from "./NotebookCard";

const coursesQyery = graphql`
  {
    solutions: allMdx(
      filter: { fields: { source: { eq: "solutions" } } }
      sort: { fields: frontmatter___order }
    ) {
      totalCount
      nodes {
        frontmatter {
          title
          subtitle
          description
          shorttitle
          slug
          level
          category_name
          background_color
          order
          not_show_in_menu
          background_image
        }
      }
    }
  }
`;


const CoursesList = ({ type }: { type: string }) => {

  const solutions = useStaticQuery(coursesQyery);
  const solutionsArray = solutions.solutions.nodes;

  return (
    <div className="w-full pt-6">
      <div className="p-6 lg:pb-10 gap-[1rem] gap-y-[1.2rem] max-w-[1190px] flex justify-center flex-wrap mx-auto">
        {solutionsArray.map((course: any, i: number) => (
          type === course.frontmatter.category_name &&
          <NotebookCard key={i} i={i+2} notebook {...course.frontmatter} />
        ))}
      </div>
    </div>
  );
};

export default CoursesList;
